const initialState = {
  dataOrderStatus: {
    orders: 0,
    orders_canceled: 0,
    orders_delivered: 0,
    orders_delivering: 0,
    orders_pending: 0,
    orders_scheduled: 0
  },
  dataPlateStatus: {
    dishes: 0,
    dishes_sold: 0,
    remaining_dishes: 0
  },
  dataPlateSales: {
    dishes_canceled: 0,
    dishes_sold: 0,
  },
  dataSalesStatus: {
    currency: "PEN",
    sales: [],
    symbol: "S/",
    total_sale: 0,
  },
  dataLocalPlates: {
    dishes_sold: 0,
    dishes_pending_payment: 0,
    remaining_dishes: 0
  },
  dataLocalRevenue: {
    revenue: 0,
    cash_revenue: 0,
    deposit_revenue: 0
  },
  dataLocalChannelOrder: [],
  dataLocalChannelRevenue: [],
};

export const REPORT_ORDER_INDEX = "REPORT_ORDER_INDEX";
export const REPORT_PLATE_STATUS_INDEX = "REPORT_PLATE_STATUS_INDEX";
export const REPORT_PLATE_SALES_INDEX = "REPORT_PLATE_SALES_INDEX";
export const REPORT_SALES_STATUS_INDEX = "REPORT_SALES_STATUS_INDEX";

export const REPORT_LOCAL_PLATE_INDEX = "REPORT_LOCAL_PLATE_INDEX";
export const REPORT_LOCAL_REVENUE_INDEX = "REPORT_LOCAL_REVENUE_INDEX";
export const REPORT_LOCAL_CHANNEL_ORDER_INDEX = "REPORT_LOCAL_CHANNEL_ORDER_INDEX";
export const REPORT_LOCAL_CHANNEL_REVENUE_INDEX = "REPORT_LOCAL_CHANNEL_REVENUE_INDEX";

export const report = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_ORDER_INDEX:
      return {
        ...state,
        dataOrderStatus: action.payload,
      };
    case REPORT_PLATE_STATUS_INDEX:
      return {
        ...state,
        dataPlateStatus: action.payload,
      };
    case REPORT_PLATE_SALES_INDEX:
      return {
        ...state,
        dataPlateSales: action.payload,
      };
    case REPORT_SALES_STATUS_INDEX:
      return {
        ...state,
        dataSalesStatus: action.payload,
      };
    case REPORT_LOCAL_PLATE_INDEX:
      return {
        ...state,
        dataLocalPlates: action.payload,
      };
    case REPORT_LOCAL_REVENUE_INDEX:
      return {
        ...state,
        dataLocalRevenue: action.payload,
      };
    case REPORT_LOCAL_CHANNEL_ORDER_INDEX:
      return {
        ...state,
        dataLocalChannelOrder: action.payload,
      };
    case REPORT_LOCAL_CHANNEL_REVENUE_INDEX:
      return {
        ...state,
        dataLocalChannelRevenue: action.payload,
      };
    default:
      return state;
  }
};

export default report;
