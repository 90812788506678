const initialState = {
  dataList: [],
  data: {},
  stocks: [],
};

export const PRODUCT_INDEX = "PRODUCT_INDEX";
export const PRODUCT_INDEXONE = "PRODUCT_INDEXONE";
export const PRODUCT_STOCKS = "PRODUCT_STOCKS";

export const product = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_INDEX:
      return {
        ...state,
        dataList: action.payload,
      };
    case PRODUCT_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case PRODUCT_STOCKS:
      return {
        ...state,
        stocks: action.payload,
      };
    default:
      return state;
  }
};

export default product;
