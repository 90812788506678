const initialState = {
  dataList: [],
  data: {},
};

export const COUPON_INDEX = "COUPON_INDEX";
export const COUPON_INDEXONE = "COUPON_INDEXONE";

export const coupon = (state = initialState, action) => {
  switch (action.type) {
    case COUPON_INDEX: 
      return {
        ...state, 
        dataList: action.payload,
      }; 
    case COUPON_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default coupon;
