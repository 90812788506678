const initialState = {
  userRole: "guest",
  userName:""
};

export const LOGIN = "LOGIN";
export const LOGIN_WITH_EMAIL = "LOGIN_WITH_EMAIL";
export const LOGOUT = "LOGOUT";
export const LOGIN_WITH_FB = "LOGIN_WITH_FB";
export const LOGIN_WITH_TWITTER = "LOGIN_WITH_TWITTER";
export const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";
export const LOGIN_WITH_GITHUB = "LOGIN_WITH_GITHUB";
export const LOGIN_WITH_JWT = "LOGIN_WITH_JWT";
export const LOGOUT_WITH_JWT = "LOGOUT_WITH_JWT";
export const LOGOUT_WITH_FIREBASE = "LOGOUT_WITH_FIREBASE";
export const CHANGE_ROLE = "CHANGE_ROLE";

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        values: action.payload,
        userRole: action.payload.role,
        userName: action.payload.full_name
      };
    }
    case LOGIN_WITH_EMAIL: {
      return { ...state, values: action.payload };
    }
    case LOGOUT: {
      return { ...state, values: action.payload, userRole: "guest" };
    }
    case LOGIN_WITH_FB: {
      return { ...state, values: action.payload };
    }
    case LOGIN_WITH_TWITTER: {
      return { ...state, values: action.payload };
    }
    case LOGIN_WITH_GOOGLE: {
      return { ...state, values: action.payload };
    }
    case LOGIN_WITH_GITHUB: {
      return { ...state, values: action.payload };
    }
    case LOGIN_WITH_JWT: {
      return { ...state, values: action.payload };
    }
    case LOGOUT_WITH_JWT: {
      return { ...state, values: action.payload };
    }
    case LOGOUT_WITH_FIREBASE: {
      return { ...state, values: action.payload };
    }
    case CHANGE_ROLE: {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};

export default auth;
