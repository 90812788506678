const initialState = {
  terms_and_conditions: {
    id: null,
    title: null,
    content: null,
  },
  privacy_policies: {
    id: null,
    title: null,
    content: null,
  },
  schedule_after_hours: {
    value: 24,
  },
  schedule_range_days: {
    value: 15,
  },
};

export const SETTING_TERMS = "SETTING_TERMS";
export const SETTING_POLICIES = "SETTING_POLICIES";
export const SETTING_SCHEDULE_AFTER_HOURS = "SETTING_SCHEDULE_AFTER_HOURS";
export const SETTING_SCHEDULE_RANGE_DAYS = "SETTING_SCHEDULE_RANGE_DAYS";

export const setting = (state = initialState, action) => {
  switch (action.type) {
    case SETTING_TERMS:
      return {
        ...state,
        terms_and_conditions: action.payload,
      };
    case SETTING_POLICIES:
      return {
        ...state,
        privacy_policies: action.payload,
      };
    case SETTING_SCHEDULE_AFTER_HOURS:
      return {
        ...state,
        schedule_after_hours: action.payload,
      };
    case SETTING_SCHEDULE_RANGE_DAYS:
      return {
        ...state,
        schedule_range_days: action.payload,
      };
    default:
      return state;
  }
};

export default setting;
