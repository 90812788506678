const initialState = {
  dates: [],
  rules: {
    data: [],
    meta: {
      current_page: 1,
      per_page: 15,
      total: 0
    }
  },
  times: [],
};

export const SCHEDULE_DATES = "SCHEDULE_DATES";
export const SCHEDULE_RULES = "SCHEDULE_RULES";
export const SCHEDULE_TIMES = "SCHEDULE_TIMES";

export const schedule = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_DATES:
      return {
        ...state,
        dates: action.payload,
      };
    case SCHEDULE_RULES:
      return {
        ...state,
        rules: action.payload,
      };
    case SCHEDULE_TIMES:
      return {
        ...state,
        times: action.payload,
      };
    default:
      return state;
  }
};

export default schedule;
