const initialState = {
  dataList: [],
  data: {},
};

export const BANNER_INDEX = "BANNER_INDEX";
export const BANNER_INDEXONE = "BANNER_INDEXONE";

export const banner = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_INDEX:
      return {
        ...state,
        dataList: action.payload,
      };
    case BANNER_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default banner;
