import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { persistor, store } from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import 'react-tippy/dist/tippy.css'
import "./index.scss"
import "./@fake-db"
import { PersistGate } from 'redux-persist/integration/react'
import { Alert } from "./layouts/components/alert/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LazyApp = lazy(() => import("./App"))

// configureDatabase()
ReactDOM.render(
    <Provider store={store}>
      <Alert />
      <ToastContainer />
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Spinner />}>
          <Layout>
              <LazyApp />
          </Layout>
        </Suspense>
      </PersistGate>
    </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
