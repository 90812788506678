const initialState = {
  dataListTicket: [],
  dataListBill: [],
};

export const RECEIPT_INDEX_TICKET = "RECEIPT_INDEX_TICKET";
export const RECEIPT_INDEX_BILL = "RECEIPT_INDEX_BILL";

export const receipt = (state = initialState, action) => {
  switch (action.type) {
    case RECEIPT_INDEX_TICKET:
      return {
        ...state,
        dataListTicket: action.payload,
      };
    case RECEIPT_INDEX_BILL:
      return {
        ...state,
        dataListBill: action.payload,
      };
    default:
      return state;
  }
};

export default receipt;
