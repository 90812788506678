import React, { useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../history"

export const Alert = () => {

  const showAlert = useSelector((state) => state.alert.showAlert);
  const title = useSelector((state) => state.alert.title);
  const message = useSelector((state) => state.alert.message);
  const button = useSelector((state) => state.alert.button);
  const back = useSelector((state) => state.alert.back);
  const init = useSelector((state) => state.alert.init);
  const dispatch = useDispatch();

  useEffect(() => {
  }, [showAlert]);

  const hiddenModal = () => {
    if(back){
      history.goBack();
    }
    if(init){
      history.replace('/login');
    }
    dispatch({ type: "HIDDEN" });
  };

  return (
    <Modal
      isOpen={showAlert}
      className="modal-theme modal-alert"
      /* backdrop={false} */
      >
      <ModalHeader>
        <p dangerouslySetInnerHTML={{ __html: title }}></p>
      </ModalHeader>

      <ModalBody>
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
      </ModalBody>

      <ModalFooter>
        <Button
          color="primary"
          className="btn-theme-secondary"
          onClick={hiddenModal}
        >
          {button}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Alert;
