const initialState = {
  dataList: [],
  data: {},
};

export const DELIVERY_INDEX = "DELIVERY_INDEX";
export const DELIVERY_INDEXONE = "DELIVERY_INDEXONE";

export const delivery = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_INDEX:
      return {
        ...state, 
        dataList: action.payload,
      }; 
    case DELIVERY_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default delivery;
