const initialState = {
  dataListHistoryStatus: [],
  dataListChannel: [],
  dataListHistory: [],
  dataListReady: [],
  dataStatus: [],
  dataList: [],
  data: {},
};

export const ORDER_CHANNEL_INDEX = "ORDER_CHANNEL_INDEX";
export const ORDER_HISTORY_INDEX = "ORDER_HISTORY_INDEX";
export const ORDER_HISTORY_STATUS_INDEX = "ORDER_HISTORY_STATUS_INDEX";
export const ORDER_READY_INDEX = "ORDER_READY_INDEX";
export const ORDER_STATUS = "ORDER_STATUS";
export const ORDER_INDEX = "ORDER_INDEX";
export const ORDER_INDEXONE = "ORDER_INDEXONE";

export const order = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_CHANNEL_INDEX:
      return {
        ...state,
        dataListChannel: action.payload,
      };
    case ORDER_HISTORY_INDEX:
      return {
        ...state,
        dataListHistory: action.payload,
      };
    case ORDER_HISTORY_STATUS_INDEX:
      return {
        ...state,
        dataListHistoryStatus: action.payload,
      };
    case ORDER_READY_INDEX:
      return {
        ...state,
        dataListReady: action.payload,
      };
    case ORDER_STATUS:
      return {
        ...state,
        dataStatus: action.payload,
      };
    case ORDER_INDEX:
      return {
        ...state,
        dataList: action.payload,
      };
    case ORDER_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default order;
