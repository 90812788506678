const initialState = {
  dataList: [],
}

export const PERIOD_INDEX = "PERIOD_INDEX";

export const period = (state = initialState, action) => {
  switch (action.type) {
    case PERIOD_INDEX:
      return {
        ...state,
        dataList: action.payload,
      }
    default:
      return state;
  }
}

export default period
