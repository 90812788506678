const initialState = {
  data: {
    id: null,
    is_active: false,
    first_name: "",
    last_name: "",
    full_name: "",
    email: "",
    role_id: null,
    role: "",
    role_description: "",
    image_url: null,
    local_name:""
  },
  response:false,
}

export const PROFILE_INDEX = "PROFILE_INDEX";
export const IND_RESPONSE = "IND_RESPONSE";

export const profile = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_INDEX:
      return {
        ...state,
        data: action.payload,
      }
    case IND_RESPONSE:
      return {
        ...state,
        response: action.payload,
      }
    default:
      return state;
  }
}

export default profile
