const initialState = {
  dataList: [],
  data: {},
};

export const CHANNEL_INDEX = "CHANNEL_INDEX";
export const CHANNEL_INDEXONE = "CHANNEL_INDEXONE";

export const channel = (state = initialState, action) => {
  switch (action.type) {
    case CHANNEL_INDEX:
      return {
        ...state,
        dataList: action.payload,
      };
    case CHANNEL_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default channel;
