import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Estado actual",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin","adminlocal"],
    navLink: "/"
  },
  {
    id: "page2",
    title: "Pedidos por otros canales",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: [""],
    navLink: "/order-channel"
  },
  {
    id: "order",
    title: "Pedidos recibidos",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin","adminlocal"],
    navLink: "/order"
  },
  {
    id: "order-ready",
    title: "Pedidos listos",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: [""],
    navLink: "/order-ready"
  },
  {
    id: "management-plates",
    title: "Gestión de productos",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin","adminlocal"],
    navLink: "/management-plates"
  },
  {
    id: "management-stock",
    title: "Stock",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/management-stock"
  },
  {
    id: "locals",
    title: "Locales",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/locals"
  },
  {
    id: "plates",
    title: "Productos",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: [""],
    navLink: "/plates"
  },
  {
    id: "delivery",
    title: "Delivery",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["adminlocal"],
    navLink: "/delivery"
  },
  {
    id: "coupons",
    title: "Cupones",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/coupons"
  },
  {
    id: "management-users",
    title: "Gestión de Usuarios",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/management-users"
  },
  {
    id: "clients",
    title: "Clientes",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin","adminlocal"],
    navLink: "/clients"
  },
  {
    id: "dealers",
    title: "Repartidores",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: [""],
    navLink: "/dealers"
  },
  {
    id: "kitchen",
    title: "Cocina",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: [""],
    navLink: "/kitchen"
  },
  {
    id: "closing",
    title: "Reporte del día",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["adminlocal"],
    navLink: "/closing"
  },
  {
    id: "closing-admin",
    title: "Reporte del día",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/closing-company"
  },
  {
    id: "report-receipt",
    title: "Reporte de comprobantes",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "adminlocal"],
    navLink: "/report-receipt"
  },
  {
    id: "settings",
    title: "Configuración",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/settings"
  },
  {
    id: "delivery-settings",
    title: "Configuración delivery",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin"],
    navLink: "/delivery-settings"
  },
  {
    id: "order-to-attend",
    title: "Órdenes por atender",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["kitchener"],
    navLink: "/order-to-attend"
  },
  {
    id: "history-orders",
    title: "Historial de órdenes",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["kitchener"],
    navLink: "/history-orders"
  },
]

export default navigationConfig
