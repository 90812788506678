const initialState = {
  dataList: [],
  data: {},
};

export const ROL_INDEX = "ROL_INDEX";
export const ROL_INDEXONE = "ROL_INDEXONE";

export const rol = (state = initialState, action) => {
  switch (action.type) {
    case ROL_INDEX:
      return {
        ...state, 
        dataList: action.payload,
      }; 
    case ROL_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default rol;
