const initialState = {
  dataList: [],
  data: {},
};

export const LOCAL_INDEX = "LOCAL_INDEX";
export const LOCAL_INDEXONE = "LOCAL_INDEXONE";

export const local = (state = initialState, action) => {
  switch (action.type) {
    case LOCAL_INDEX:
      return {
        ...state, 
        dataList: action.payload,
      }; 
    case LOCAL_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default local;
