const initialState = {
  dataList: [],
  data: {
    image_url:null
  },
};

export const ADDITIONAL_INDEX = "ADDITIONAL_INDEX";
export const ADDITIONAL_INDEXONE = "ADDITIONAL_INDEXONE";
export const ADDITIONAL_INDEXONE_IMAGE = "ADDITIONAL_INDEXONE_IMAGE";

export const additional = (state = initialState, action) => {
  switch (action.type) {
    case ADDITIONAL_INDEX:
      return {
        ...state,
        dataList: action.payload,
      };
    case ADDITIONAL_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default additional;
