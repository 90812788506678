import { combineReducers } from "redux"
import customizer from "./customizer/"
import navbar from "./navbar/Index"
import alert from "./alert/alertReducer"
import local from "./local/localReducer"
import category from "./category/categoryReducer";
import product from "./product/productReducer"
import additional from "./additional/additionalReducer"
import coupon from "./coupon/couponReducer"
import user from "./user/userReducer"
import rol from "./rol/rolReducer"
import customer from "./customer/customerReducer"
import dealer from "./dealer/dealerReducer"
import delivery from "./delivery/deliveryReducer"
import ubigee from "./ubigee/ubigeeReducer"
import order from "./order/orderReducer"
import auth from "./auth/authReducer"
import profile from "./profile/profileReducer"
import report from "./report/reportReducer"
import condition from "./condition/conditionReducer"
import channel from "./channel/channelReducer"
import period from "./period/periodReducer"
import receipt from "./receipt/receiptReducer"
import banner from "./banner/bannerReducer"
import setting from "./setting/settingReducer"
import schedule from "./schedule/scheduleReducer"

const rootReducer = combineReducers({
  customizer: customizer,
  navbar: navbar,
  alert: alert,
  local: local,
  category: category,
  product: product,
  additional: additional,
  coupon: coupon,
  user: user,
  rol: rol,
  customer: customer,
  dealer: dealer,
  delivery: delivery,
  ubigee: ubigee,
  order: order,
  auth: auth,
  profile: profile,
  report: report,
  condition: condition,
  channel: channel,
  period: period,
  receipt: receipt,
  banner: banner,
  schedule: schedule,
  setting: setting,
})

export default rootReducer
