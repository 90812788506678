const initialState = {
  dataList: [],
  data: {},
};

export const CUSTOMER_INDEX = "CUSTOMER_INDEX";
export const CUSTOMER_INDEXONE = "CUSTOMER_INDEXONE";

export const customer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_INDEX:
      return {
        ...state, 
        dataList: action.payload,
      }; 
    case CUSTOMER_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default customer;
