export const CATEGORY_INDEX = "CATEGORY_INDEX";
export const CATEGORY_INDEXONE = "CATEGORY_INDEXONE";

const initialState = {
    dataList: [],
    data: {}
}

export const category = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_INDEX:
            return {
                ...state,
                dataList: action.payload,
            }
        case CATEGORY_INDEXONE:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state;
    }
}

export default category