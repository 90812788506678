const initialState = {
  dataList: {
    data: []
  },
  data: {},
};

export const UBIGEE_INDEX = "UBIGEE_INDEX";
export const UBIGEE_INDEXONE = "UBIGEE_INDEXONE";

export const ubigee = (state = initialState, action) => {
  switch (action.type) {
    case UBIGEE_INDEX:
      return {
        ...state,
        dataList: action.payload,
      };
    case UBIGEE_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default ubigee;
