const initialState = {
  dataListAvailable:[],
  dataList: [],
  data: {},
};

export const DEALER_INDEX = "DEALER_INDEX";
export const DEALER_AVAILABLE_INDEX = "DEALER_AVAILABLE_INDEX";
export const DEALER_INDEXONE = "DEALER_INDEXONE";

export const dealer = (state = initialState, action) => {
  switch (action.type) {
    case DEALER_INDEX:
      return {
        ...state,
        dataList: action.payload,
      };
    case DEALER_AVAILABLE_INDEX:
      return {
        ...state,
        dataListAvailable: action.payload,
      };
    case DEALER_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default dealer;
