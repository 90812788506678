import React, {useState, useEffect} from "react"
import { NavLink } from "react-router-dom"
import {useSelector} from 'react-redux';
import { Navbar } from "reactstrap"
import classnames from "classnames"
import * as Icon from "react-feather"

import LogoCompany from 'dsala-resources/images/admin-restaurant/navbar/logo.svg'
import icBack from "dsala-resources/images/admin-restaurant/register/ic_back.svg";
import { history } from "../../../history";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import NavbarUser from "./NavbarUser"
import NavbarBookmarks from "./NavbarBookmarks"

const ThemeNavbar = props => {
  const [width, setWidth] =  useState()
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  const userRole = useSelector(state => state.auth.userRole)

  useEffect(() => {
    setWidth(window.innerWidth)
  })

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu  ",
          {
            "navbar-shadow-tablet": width <= 768,
            "navbar-shadow-desktop": width >= 768,
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              id="navbar-mobile"
              className={`navbar-collapse d-flex ${width <= 768 ?
                'justify-content-between' :
                (history.location.pathname.split("/").length > 2 ? 'justify-content-between' : 'justify-content-end')}
                align-items-center`}
            >
              {
                history.location.pathname.split("/").length > 2 ? (
                  // <img
                  //   src={icBack}
                  //   className="icon-back-navbar c-pointer"
                  //   alt="img-backNavbar"
                  //   onClick={() => { history.goBack() }}
                  // />
                  <a
                    className="c-pointer"
                    onClick={() => { history.goBack() }}>
                    <Icon.ArrowLeft className="ficon" />
                  </a>
                ) : (
                    width <= 768 &&
                    <NavLink to="/" className="navbar-brand">
                      <img src={LogoCompany} alt="Logo" style={{ maxHeight: 36 }} />
                    </NavLink>
                  )
              }

              {/* {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null} */}

              <div className="d-flex align-items-center">
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName={userRole}
                  userImg={userImg}
                />
                {
                  (userRole != 'kitchener' || width > 768)&&
                  <div className="bookmark-wrapper">
                    <NavbarBookmarks
                      sidebarVisibility={props.sidebarVisibility}
                      handleAppOverlay={props.handleAppOverlay}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

export default ThemeNavbar
