const initialState = {
  dataList: [],
  data: {},
};

export const USER_INDEX = "USER_INDEX";
export const USER_INDEXONE = "USER_INDEXONE";

export const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_INDEX:
      return {
        ...state, 
        dataList: action.payload,
      }; 
    case USER_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default user;
